import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as I18n from '@rushplay/i18n'
import { lazy as loadable } from '@loadable/component'

import * as Configuration from './configuration'
import * as ServerConfig from './server-configuration'

const BriteLoginForm = loadable(() => import('./brite-login-form'))
const LoginForm = loadable(() => import('./login-form'))

export function LoginBox() {
  const i18n = I18n.useI18n()
  const { country } = ServerConfig.useContext()

  const manualSignupEnabled = ReactRedux.useSelector(state =>
    Jurisdiction.getManualSignUpAllowed(state.jurisdiction)
  )
  const briteSigninEnabled = ReactRedux.useSelector(state =>
    Configuration.getBriteSigninEnabled(state.configuration)
  )

  if (!country.enabled) {
    return (
      <React.Fragment>
        <LoginForm />
        <Common.Box display="flex" flexDirection="column" alignItems="center">
          <Common.Box px={2} pb={2} pt={7} display="flex" flexDirection="row">
            <Common.Box fontSize={7}>🌍</Common.Box>
            <Common.Box fontSize={3} pl={1}>
              {i18n.translate('unsupported-market.information')}
            </Common.Box>
          </Common.Box>
        </Common.Box>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {manualSignupEnabled && (
        <React.Fragment>
          <LoginForm />
          <Common.Box pt={2} textAlign="center" fontSize={2}>
            {i18n.translate('login-page.create-account.content')}
            <Common.Space pt={0}>
              <ReactRouter.Link
                to="?register=me"
                data-testid="login-page-register"
              >
                <Common.Text
                  textDecoration="underline"
                  fontWeight="bold"
                  font="head"
                >
                  {i18n.translate('login-page.create-account.cta')}
                </Common.Text>
              </ReactRouter.Link>
            </Common.Space>
          </Common.Box>
        </React.Fragment>
      )}

      {briteSigninEnabled && <BriteLoginForm />}
    </React.Fragment>
  )
}

// For @loadable/components
export default LoginBox
